import { iBigNumber } from "../Helper/interfaces";

export default class BigNumber implements iBigNumber{
    digits=[]
    digitSize= 3;
    signs=[
        "",
        "K",
        "M",
        "B",
        "T",
        "a",
        "ab",
        "ac",
        "ad",
        "ae",
        "af"
    ]
    increase(amount:number){
        if(amount >= 0){
            this.addInt(amount);
        }else{
            this.subtractInt(amount);
        }
    }
    addInt(amount:number) {
            let carry=0;
            let i=0;
            while(amount>0){
                if(i>= this.digits.length){
                    this.digits.push(0);
                }
                let sum=this.digits[i]+ (amount % (10** this.digitSize)) + carry;
                this.digits[i]=sum % (10** this.digitSize);
                carry= Math.floor(sum/(10** this.digitSize));
                amount=Math.floor(amount / (10** this.digitSize));
                i++;       
            }
            while(carry>0){
                if(i>= this.digits.length){
                    this.digits.push(0);
                }
                let sum = this.digits[i]+ carry;
                this.digits[i]=sum % (10 ** this.digitSize);
                carry=Math.floor(sum / (10 ** this.digitSize));
                i++;
            }
        this.RemoveExcess();
    
    }

    addBig(amount: iBigNumber){
        let size=amount.digits.length;
        let carry=0
        for(let i = 0; i< size ; i++){
            if(i>=this.digits.length)
                this.digits.push(0);
            let sum = this.digits[i]+ amount.digits[i]+ carry;
            this.digits[i]=sum % (10 ** this.digitSize);
            carry=Math.floor(sum/(10** this.digitSize))
        }
        let index=size;
        while(carry>0){
            if(index>=this.digits.length)
                this.digits.push(0);
            let sum = this.digits[index]+ carry;
            this.digits[index]=sum % (10 ** this.digitSize);
            carry=Math.floor(sum/(10** this.digitSize))
            index++;
        }
        this.RemoveExcess();
    }

    subtractInt(amount:number){
        amount=Math.abs(amount);
        let big=ConvertToBigNum(amount);
        this.subtractBig(big);
    }

    subtractBig(amount: iBigNumber) {
        if(this.isGreaterBig(amount)){
            let carry=0;
            let i =0
            for(;i<amount.digits.length;i++){
                let sub= (this.digits[i] - carry) - amount.digits[i];
                if(sub < 0){
                    sub *=-1;
                    this.digits[i]=(10**this.digitSize)-(sub% (10** this.digitSize));
                    carry= Math.floor(sub/(10 ** this.digitSize))+1;
                }
                else{
                    this.digits[i]=sub;
                    carry=0;
                }
            }
            while(carry>0){
                let sub= (this.digits[i] - carry);
                if(sub<0){
                    sub*=-1;
                    this.digits[i]= (10**this.digitSize) -(sub%(10**this.digitSize));
                    carry=Math.floor(sub/(10**this.digitSize))+1;
                }else{
                    this.digits[i]=sub;
                    carry=0;
                }
                i++;
            }
            this.RemoveExcess();
        }
        else{
            this.digits=[0];
        }
    };

    RemoveExcess(){
        let index=this.digits.length-1;
        while(index>0){
            if(this.digits[index]==0){
                this.digits.splice(index,1)
            }
            else{
                break;
            }
            index--;
        }
    }

    isGreater(amount: number){
        let bigamount=ConvertToBigNum(amount);
        return this.isGreaterBig(bigamount);
    } 
    isGreaterBig(amount: iBigNumber) {
        let thisIndex=this.digits.length-1;
        let otherIndex= amount.digits.length-1;
        while(thisIndex>=0 || otherIndex >=0){
            if(thisIndex != otherIndex){
                if(thisIndex>otherIndex){
                    if(this.digits[thisIndex]>0){
                        return true;
                    }
                    thisIndex--;
                }
                else{
                    if(amount.digits[otherIndex]>0){
                        return false;
                    }
                    otherIndex--;
                }
            }
            else{
                if(this.digits[thisIndex] > amount.digits[otherIndex]){
                    return true;
                }
                if(this.digits[thisIndex] < amount.digits[otherIndex]){
                    return false;
                }
                thisIndex--;
                otherIndex--;
            }
        }
        return true;
    }

    present() {
        if(this.digits.length==0){
            return "0";
        }
        let index= this.digits.length - 1;
        if(index==0)
            return this.digits[index].toString()+this.signs[index];
        let res=this.digits[index].toString();
        if(res.length <this.digitSize){
            // console.log(this.digits[index-1]/100);
            let nextDigits=this.digits[index-1]/100<1?"0"+this.digits[index-1].toString():this.digits[index-1].toString()
            res+="."+nextDigits.slice(0,this.digitSize-res.length)
        }
        res+=this.signs[index];
        return res;
    }
    toNumber(){
        let result=0;
        let multiplier=1;
        for(let i=0; i<this.digits.length;i++){
            result +=this.digits[i]* multiplier;
            multiplier *= 10**this.digitSize;
        }
        return result; 
    }
    isZero(){
        let result=true;
        this.digits.forEach(digit=> {
            if(digit > 0)
                result= false;
            
        })
        return result;
    }

    //TODO the digit sizes should be the same
    equals(amount:BigNumber){
        if(this.digits.length != amount.digits.length){
            return false;
        }
        let result=true;
        this.digits.forEach((item,index)=>{
            if(item != amount.digits[index]){
                result= false;
            }
        })
        return result;        
    }

    deserialize(digits: number[], digitSize: number) {
        this.digits=digits;
        this.digitSize=digitSize;
    };
    clone(){
        let clone=new BigNumber();
        clone.deserialize([...this.digits],this.digitSize);
        return clone;
    }

    multiply(val: number) {
        let parts=[];
        let size=this.digits.length;
        for(let i=0;i<size;i++){
            parts.push(val * this.digits[i]);
        }
        let partsBig=[];
        parts.forEach((part,index) => {
            let npart=new BigNumber();
            for(let i=index;i>=0;i--){
                let wholePart = Math.floor(part);
                npart.setPart(i,wholePart);
                part=(part-wholePart) * 10 ** this.digitSize;
            }
            npart.RemoveExcess();
            partsBig.push(npart);
        });
        let result= new BigNumber();
        partsBig.forEach(part=>{
            result.addBig(part);
        })
        this.digits=result.digits;
    }

    setPart(index:number,value:number){
        while(index>=this.digits.length){
            this.digits.push(0);
        }
        do{
            if(index>this.digits.length)
                this.digits.push(value % (10 ** this.digitSize))
            else
                this.digits[index]=value % (10 ** this.digitSize) 
            value=Math.floor(value / (10 ** this.digitSize))
            index++
        }while(value>0)
    }
}
export function ConvertToBigNum(amount:number){
    let num= new BigNumber();
    let index=0;
    while(amount > 0){
        num.digits.push( (amount % (10 ** num.digitSize )) );
        amount=Math.floor(amount/ 10 ** num.digitSize);
        index++;
    }
    return num;
}
export function AddBigNumber(og:iBigNumber,amount:number){
    let big=new BigNumber();
    big.deserialize([...og.digits],og.digitSize);
    big.increase(amount);
    return big;
}

export function MakeBigNumber(digits: number[], digitSize: number):iBigNumber{
    let result= new BigNumber;
    result.deserialize([...digits],digitSize);
    return result
}

export function GetPercentage(value:iBigNumber,base:iBigNumber):number{
    if(value.digits.length>base.digits.length)
        return 1;
    if(base.digits.length-value.digits.length>=2)
        return 0;
    if(value.digits.length==1 && base.digits.length==1){
        let result=value.digits[0]/base.digits[0];
        result=result>1?1:result;
        return result;
    }
    let valnum=0;
    let baseNum=1;
    if(base.digits.length-value.digits.length==0){
        valnum=value.digits[value.digits.length-2]+ (value.digits[value.digits.length-1] * 10 ** value.digitSize)
    }
    else{
        valnum=value.digits[value.digits.length-1];
    }

    baseNum=base.digits[base.digits.length-2]+(base.digits[base.digits.length-1] * 10** base.digitSize)
    let result=valnum/baseNum;
    result=result>1?1:result;
    return result;
}

export function GetMultiplicationBig(big:iBigNumber,mul:number ){
    let result=big.clone();
    result.multiply(mul);
    return result;
}