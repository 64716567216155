import React from "react";
import BouncyEffect from "@/Components/Game/GeneralComponents/Effects/BouncyEffect";

export default function Loading({className='w-[50px] h-[50px]'}){

    return (
        <div className="w-full h-full mx-auto max-w-xl flex flex-wrap content-center justify-center">
            <div className={className}>
            <BouncyEffect/>
            </div>
        </div>
    );
}
